:root,
html,
body {
  margin: 0;
  height: 100%;
  color: #fff;
  background-color: #222;
}
.rhythm-game * {
  all: unset;
}
.rhythm-game iframe {
  border-style: none;
}
.rhythm-game input {
  appearance: none;
}
.rhythm-game input[type="number"]::-webkit-outer-spin-button,
.rhythm-game input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.rhythm-game input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}
.rhythm-game {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.rhythm-game .source-container {
  position: absolute;
  width: 100%;
  height: 100%;
}
.rhythm-game .source-container .source {
  position: absolute;
  width: 100%;
  height: 100%;
}
.rhythm-game .source-container .source:not(.selected) {
  display: none;
}
.rhythm-game .launcher {
  position: absolute;
  width: 100%;
  height: 100%;
}
.rhythm-game .game {
  position: absolute;
  width: 100%;
  height: 100%;
}
.rhythm-game .game {
  --over-wait: 3000;
  --offset: 0;
  --delay: 0;
  --duration: 1000;
  --judge-line-y: 0.96;
}
.rhythm-game .game .view {
  position: absolute;
  width: 100%;
  height: 100%;
}
.rhythm-game .game .result {
  position: absolute;
  width: 100%;
  height: 100%;
  visibility: hidden;
}
.rhythm-game .game.overed .result {
  visibility: visible;
}
.rhythm-game .game .lanes {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(var(--lane-amount), 1fr);
  grid-template-rows: 1fr;
}
.rhythm-game .game .note {
  will-change: transform;
  animation-name: fall;
  animation-play-state: paused;
  animation-delay: calc((var(--offset) + var(--delay) - var(--duration) * var(--judge-line-y)) * 1ms);
  animation-duration: calc(var(--duration) * 1ms);
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  grid-column: calc(1 + var(--position));
  grid-row: 1;
  position: relative;
  width: 100%;
  height: 100%;
  --judge-shrink: calc(var(--duration) * 10 / 1000);
  --judge-ms: 60;
}
.rhythm-game .game .note.wait,
.rhythm-game .game .note:not([data-judge=""]) {
  visibility: hidden;
}
.rhythm-game .game .note:not([data-judge=""]) {
  will-change: auto;
}
.rhythm-game .game.restarting .note {
  animation: none;
}
.rhythm-game .game.playing .note {
  animation-play-state: running;
}
.rhythm-game .game .note .judge-container {
  width: 100%;
  height: calc(var(--judge-ms) / var(--judge-shrink) * 4 * 1%);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.rhythm-game .game .note .judge-container .view {
  position: absolute;
  width: 100%;
}
.rhythm-game .game .note .judge-container .judge {
  width: 100%;
  height: 100%;
}
.rhythm-game .game .judge-line {
  position: absolute;
  top: calc(100% * var(--judge-line-y));
}
.rhythm-game .game .progress {
  position: absolute;
  width: 0;
  animation-name: progress;
  animation-play-state: paused;
  animation-duration: calc((var(--duration) + var(--over-wait)) * 1ms);
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}
.rhythm-game .game.restarting .progress {
  animation: none;
}
.rhythm-game .game.playing .progress {
  animation-play-state: running;
}
.rhythm-game .game .controller {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.rhythm-game .game.playing .controller {
  width: 2em;
  height: 2em;
}
.rhythm-game .game.overed .controller {
  display: none;
}
.rhythm-game .game:not(.playing) .pause {
  display: none;
}
.rhythm-game .game:not(.paused) .in-game-menu {
  display: none;
}
@-moz-keyframes fall {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}
@-webkit-keyframes fall {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}
@-o-keyframes fall {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}
@keyframes fall {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}
@-moz-keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@-webkit-keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@-o-keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.rhythm-game .game {
  position: absolute;
  width: 100%;
  height: 100%;
}
.rhythm-game .game.preview {
  display: none;
}
.rhythm-game .game .action-detector {
  touch-action: none;
  position: absolute;
  width: 100%;
  height: 100%;
}
.rhythm-game .game .player {
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  width: 100%;
  height: 100%;
}
.rhythm-game .game .progress {
  height: 1px;
  bottom: 0;
  background-color: #fff;
}
.rhythm-game .game .view {
  perspective: 10vmin;
  perspective-origin: top;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rhythm-game .game .lanes {
  transform: rotateX(45deg);
  transform-origin: bottom;
  overflow: hidden;
  background: rgba(0,0,0,0.2);
}
.rhythm-game .game .judge-line {
  width: 100%;
  height: 0;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 1px;
  background: rgba(255,0,0,0.2);
}
.rhythm-game .game .judge-view {
  pointer-events: none;
  position: absolute;
  background: rgba(0,0,0,0.4);
  font-size: 3em;
  font-weight: 600;
}
.rhythm-game .game .note .view {
  height: 4mm;
  background-color: #ffa500;
}
.rhythm-game .game .note .judge {
  box-sizing: border-box;
  border: 1px solid #fff;
  opacity: 0.2;
}
.rhythm-game .game .tap-effect {
  position: absolute;
  left: calc(100% * var(--x));
  width: 1px;
  height: 100%;
  background-color: #fff;
}
.rhythm-game .game .result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(51,51,51,0.6);
}
.rhythm-game .game .result .judge-count {
  display: grid;
  justify-content: center;
  align-content: center;
  column-gap: 1.2em;
}
.rhythm-game .game .result .judge-count .judge {
  grid-column: 1;
}
.rhythm-game .game .result .judge-count .count {
  grid-column: 2;
}
.rhythm-game .game .in-game-menu {
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.rhythm-game .game .in-game-menu .actions {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.rhythm-game .game .in-game-menu .actions .button {
  width: 100%;
  height: 100%;
}
.rhythm-game .game .in-game-menu .actions .button.play {
  background: rgba(255,255,255,0.2);
}
.rhythm-game .game .in-game-menu .actions .button.restart {
  background: rgba(0,0,0,0.2);
}
.rhythm-game .game .in-game-menu .actions .button.exit {
  background: rgba(0,0,0,0.6);
}
.rhythm-game .game .in-game-menu .parameters {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.rhythm-game .game .in-game-menu .parameters fieldset {
  width: 100%;
}
.rhythm-game .game .in-game-menu .parameters legend {
  text-align: center;
}
.rhythm-game .game .number-inputter {
  position: relative;
  width: 100%;
}
.rhythm-game .game .number-inputter input[type="number"] {
  width: 100%;
  text-align: center;
}
.rhythm-game .game .number-inputter input[type="range"] {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  border-style: solid;
  border-width: 0;
  border-top-width: 1px;
}
.rhythm-game .game .number-inputter input[type="range"]::-webkit-slider-thumb {
  width: 1em;
  height: 0.55em;
  background-color: #fff;
  border-radius: 0 0 25% 25%;
}
.rhythm-game .game:not(.preview) + .game-select-menu {
  display: none;
}
.rhythm-game .game-select-menu {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.rhythm-game .game-select-menu >h1 {
  display: none;
}
.rhythm-game .game-select-menu .selection {
  width: 10em;
  height: 10em;
  border: 1px solid #000;
  background: rgba(0,0,0,0.4);
}
.rhythm-game .game-select-menu .selection.selected {
  color: #000;
  background: rgba(255,255,255,0.4);
}
.rhythm-game .screen-transition {
  position: absolute;
  width: 100%;
  height: 100%;
}
.rhythm-game .screen-transition.intro {
  animation-name: dummy-intro;
}
.rhythm-game .screen-transition.outro {
  animation-name: dummy-outro;
}
@-moz-keyframes dummy-intro {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(0);
  }
}
@-webkit-keyframes dummy-intro {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(0);
  }
}
@-o-keyframes dummy-intro {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes dummy-intro {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(0);
  }
}
@-moz-keyframes dummy-outro {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(0);
  }
}
@-webkit-keyframes dummy-outro {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(0);
  }
}
@-o-keyframes dummy-outro {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes dummy-outro {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(0);
  }
}
.rhythm-game .screen-transition {
  pointer-events: none;
}
.rhythm-game .screen-transition.game-start {
  animation-duration: 1s;
  background: rgba(255,255,255,0.8);
}
.rhythm-game .screen-transition.game-start.intro {
  animation-name: intro;
}
.rhythm-game .screen-transition.game-start.outro {
  animation-name: outro;
}
@-moz-keyframes intro {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@-webkit-keyframes intro {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@-o-keyframes intro {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes intro {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@-moz-keyframes outro {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
@-webkit-keyframes outro {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
@-o-keyframes outro {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
@keyframes outro {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
